import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { trendingSearchList } from 'common/constants';

const styles = (theme) => ({
  root: {
    // padding: "10px",
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
    },
  },
  searchField: {
    width: '100%',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    '& .MuiInputBase-root': {
      paddingRight: '9px !important',
    },

    '& div': {
      paddingRight: 0,
    },
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.2) !important',
    },
  },
  searchIcon: {
    fontSize: '1.7rem',
  },
  button: {
    width: '60px',
    height: '56px',
    marginRight: '-10px',
    borderRadius: '0 4px 4px 0',
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  isSearching: {
    width: '16px',
    height: '16px',
    display: 'inline-block',
    border: '8px solid rgb(205, 209, 212)',
    margin: '4px',
    animation: ' $searchAnimation 0.85s linear 0s infinite normal none running',
    opacity: '0.7',
  },
  '@keyframes searchAnimation': {
    '0%': {
      borderColor:
        'rgb(205, 209, 212) rgb(232, 233, 234) rgb(232, 233, 234) rgb(205, 209, 212)',
    },
    '35%': {
      borderColor:
        'rgb(205, 209, 212) rgb(205, 209, 212) rgb(232, 233, 234) rgb(232, 233, 234)',
      borderRadius: '50%',
    },
    '50%': {
      borderColor:
        'rgb(232, 233, 234) rgb(205, 209, 212) rgb(205, 209, 212) rgb(232, 233, 234)',
      borderRadius: '50%',
    },

    '55%': {
      borderColor:
        'rgb(232, 233, 234) rgb(232, 233, 234) rgb(205, 209, 212) rgb(205, 209, 212)',
      borderRadius: '50%',
    },
    '100%': {
      borderColor:
        'rgb(205, 209, 212) rgb(232, 233, 234) rgb(232, 233, 234) rgb(205, 209, 212)',
      transform: 'rotate(360deg)',
    },
  },
});

const SearchForm = (props) => {
  const { classes, onSubmit, onFocus, onBlur, initialValues, isLoading } =
    props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const { register, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const loadData = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setOptions(trendingSearchList.map((option) => option));
    }, 1500);
  };

  const handleOpen = () => {
    setOpen(true);
    loadData();
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const SearchInput = (({ inputProps }) => (
    <Autocomplete
      {...register(inputProps.name)}
      freeSolo
      id="searchbar"
      disableClearable={true}
      variant="outlined"
      disabled={isLoading}
      loading={loading}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          className={classes.searchField}
          field={inputProps.name}
          name={inputProps.name}
          placeholder={inputProps.placeholder}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disabled={isLoading}
                  className={classes.button}
                  size="medium"
                  type="submit"
                >
                  <Choose>
                    <When condition={isLoading}>
                      <i className={classes.isSearching} />
                    </When>
                    <Otherwise>
                      <SearchIcon className={classes.searchIcon} />
                    </Otherwise>
                  </Choose>
                </Button>
              </InputAdornment>
            ),
          }}
        />
      )}
      onChange={(event, newValue) => {
        setValue(inputProps.name, newValue);
      }}
    />
  ))({
    label: 'Search',
    inputProps: {
      name: 'searchText',
      type: 'search',
      placeholder: 'Looking for something special?',
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onFocus={onFocus}
      onBlur={onBlur}
      noValidate
      className={classes.root}
      autoComplete="off"
    >
      {SearchInput}
    </form>
  );
};

SearchForm.defaultProps = {
  initialValues: {},
  onBlur: () => null,
  onFocus: () => null,
  onSubmit: () => null,
  isLoading: true,
};

SearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default withStyles(styles)(SearchForm);

// import React from "react";
// import Router from "next/router";
// import { withStyles } from "@material-ui/core/styles";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import TextField from "@material-ui/core/TextField";
// import SvgIcon from "@material-ui/core/SvgIcon";
// import Button from "@material-ui/core/Button";

// const styles = (theme) => ({
//   searchField: {
//     width: "100%",
//     borderRadius: "6px",
//     backgroundColor: "#FFFFFF",
//     borderColor: "rgba(0, 0, 0, 0.08)",
//     padding: 0,
//     [theme.breakpoints.down("sm")]: {
//       height: "16px",
//       maxWidth: "460px",
//     },
//     "&:hover": {
//       borderColor: "rgba(0, 0, 0, 0.1) !important",
//     },
//     "& div": {
//       paddingRight: 0,
//     },
//     "& fieldset": {
//       borderColor: "rgba(0, 0, 0, 0.1) !important",
//     },
//     "& input": {
//       padding: 0,
//       paddingLeft: 20,
//       height: 55,
//       color: "rgba(0, 0, 0, 0.8)",
//       letterSpacing: 1,
//       [theme.breakpoints.down("md")]: {
//         paddingLeft: 10,
//         fontSize: 14,
//       },
//     },
//   },
//   button: {
//     "&:hover": {
//       color: theme.palette.secondary.dark,
//     },
//   },
// });

// function SearchIcon(props) {
//   return (
//     <SvgIcon {...props}>
//       <path d="M20.756 18.876l6.155 6.154-1.88 1.881-6.155-6.155A9.269 9.269 0 0 1 13.3 22.61a9.31 9.31 0 1 1 9.31-9.31c0 2.091-.69 4.021-1.854 5.576zM13.3 19.95a6.65 6.65 0 1 0 0-13.3 6.65 6.65 0 0 0 0 13.3z"></path>
//     </SvgIcon>
//   );
// }

// class SearchForm extends React.Component {
//   componentDidMount() {
//     document.addEventListener("click", this.handleWindowClick);
//   }

//   componentWillUnmount() {
//     document.removeEventListener("click", this.handleWindowClick);
//   }

//   render() {
//     const { classes, onSubmit, onFocus, onBlur, initialValues } = this.props;

//     const SearchInput = (({ inputProps }) => (
//       <Field name={inputProps.name}>
//         {({ input }) => (
//           <Autocomplete
//             // autoComplete="off"
//             field={inputProps.name}
//             name={inputProps.name}
//             placeholder={inputProps.placeholder}
//             className={classes.searchField}
//             variant="outlined"
//             margin="normal"
//             onFocus={input.onFocus}
//             onBlur={input.onBlur}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <Button
//                     className={classes.button}
//                     size="medium"
//                     type="submit"
//                   >
//                     <SearchIcon />
//                   </Button>
//                 </InputAdornment>
//               ),
//             }}
//             {...input}
//           />
//         )}
//       </Field>
//     ))({
//       label: "Search",
//       inputProps: {
//         name: "searchText",
//         type: "text",
//         placeholder: "Looking for something special?",
//       },
//     });

//     const SubmitForm = ({ handleSubmit }) => (
//       <form onSubmit={handleSubmit} onFocus={onFocus} onBlur={onBlur} noValidate autoComplete="off">
//         {SearchInput}
//       </form>
//     );

//     return <Form onSubmit={onSubmit} initialValues={initialValues}>{SubmitForm}</Form>;
//   }
// }

// export default withStyles(styles)(SearchForm);

// InputProps = {{
//   endAdornment: (
//     <InputAdornment position="end">
//       <Button
//         className={classes.button}
//         size="medium"
//         type="submit"
//       >
//         <SearchIcon />
//       </Button>
//     </InputAdornment>
//   ),
//             }}
// {...input }
