import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import { makeStyles } from '@material-ui/core/styles';
import { TextInputWrapper } from 'common/components/Forms/Wrappers';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  searchField: {
    width: '100%',
    maxWidth: '540px',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    borderColor: 'rgba(0, 0, 0, 0.08)',
    padding: 0,
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.1) !important',
    },
    '& div': {
      paddingRight: 0,
    },
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.1) !important',
    },
    '& input': {
      padding: 0,
      paddingLeft: 20,
      height: 55,
      color: 'rgba(0, 0, 0, 0.8)',
      letterSpacing: 1,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        fontSize: 14,
      },
    },
  },
  button: {
    height: '55px',
    width: '180px',
    color: '#FFFFFF',
    letterSpacing: 1,
    fontSize: 16,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('md')]: {
      width: 120,
    },
  },
  secondaryButton: {
    marginTop: theme.spacing(1),
  },
}));

const schema = Joi.object({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.empty': 'Please fill in your email',
      'any.required': 'Please fill in your email',
      'string.email': 'Email not valid',
    }),
});

const EmailSignupForm = (props) => {
  const { width } = props;
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schema),
    defaultValues: props.initialValues,
  });
  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={handleSubmit(props.onSubmit)}>
      <Choose>
        <When condition={isWidthUp('sm', width)}>
          <TextInputWrapper
            control={control}
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            variant="outlined"
            className={classes.searchField}
            autoComplete="email"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    className={classes.button}
                    size="medium"
                    type="submit"
                  >
                    Signup
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </When>
        <Otherwise>
          <Fragment>
            <div>
              <TextInputWrapper
                control={control}
                margin="normal"
                fullWidth
                label="Email Address"
                name="email"
                variant="outlined"
                className={classes.searchField}
                autoComplete="email"
              />
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.secondaryButton}
            >
              Signup
            </Button>
          </Fragment>
        </Otherwise>
      </Choose>
    </form>
  );
};

EmailSignupForm.defaultProps = {
  initialValues: {},
  onSubmit: () => null,
};

EmailSignupForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  width: PropTypes.string.isRequired,
};

export default withWidth()(EmailSignupForm);
