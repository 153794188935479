import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { ReCaptchaError } from './styles';

import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_KEY } from 'config';
import NumberFormat from 'react-number-format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { useController } from 'react-hook-form';
import { path } from 'ramda';

const styles = () => ({
  numberCharacters: {
    color: 'gray',
    fontSize: '12px',
    fontWeight: '500',
  },
});

const useStyle = makeStyles(styles);

export const CheckboxWrapper = ({
  input: { checked, name, onChange, ...restInput },
  // eslint-disable-next-line no-unused-vars
  meta,
  ...rest
}) => (
  <Checkbox
    {...rest}
    name={name}
    inputProps={restInput}
    onChange={onChange}
    checked={!!checked}
  />
);

export const RadioWrapper = ({
  input: { checked, value, name, onChange, ...restInput },
  // eslint-disable-next-line no-unused-vars
  meta,
  ...rest
}) => (
  <Radio
    {...rest}
    name={name}
    inputProps={restInput}
    onChange={onChange}
    checked={!!checked}
    value={value}
  />
);

export const TextFieldWrapper = (props) => {
  const { input, meta, ...rest } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <TextField
      {...input}
      {...rest}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
    />
  );
};

export const TextInputWrapper = (props) => {
  const { control, name, defaultValue, disabled, ...rest } = props;
  const { field, fieldState, formState } = useController({
    control,
    name,
    defaultValue,
  });

  const showError = fieldState.error;
  const fieldError = path(['error', 'message'], fieldState);

  return (
    <TextField
      {...field}
      {...rest}
      disabled={disabled || formState.isSubmitting}
      helperText={fieldError}
      error={showError}
    />
  );
};

export const ReCaptchaInputWrapper = (props) => {
  const { control, name, defaultValue } = props;

  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
  });

  const showError = fieldState.error;
  const fieldError = path(['error', 'message'], fieldState);

  return (
    <div>
      <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_KEY} {...field} />
      {showError && <ReCaptchaError>{fieldError}</ReCaptchaError>}
    </div>
  );
};

export const ReCaptchaWrapper = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { input, meta, ...rest } = props;

  return (
    <div>
      <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_KEY} onChange={input.onChange} />
      {meta.error && meta.touched && (
        <ReCaptchaError>{meta.error}</ReCaptchaError>
      )}
    </div>
  );
};

export const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
};

export const NumberFormatWrapper = (props) => {
  const { input, meta, ...rest } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <TextField
      {...input}
      {...rest}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

export const NumberInputFormatWrapper = (props) => {
  const { control, name, defaultValue, disabled, ...rest } = props;
  const { field, fieldState, formState } = useController({
    control,
    name,
    defaultValue,
  });

  const showError = fieldState.error;
  const fieldError = path(['error', 'message'], fieldState);

  return (
    <TextField
      {...field}
      {...rest}
      disabled={disabled || formState.isSubmitting}
      helperText={fieldError}
      error={showError}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

export const CheckboxWithLabelWrapper = ({
  input: { checked, name, onChange, ...restInput },
  // eslint-disable-next-line no-unused-vars
  meta,
  ...rest
}) => (
  <FormControlLabel
    control={
      <Checkbox
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={onChange}
        checked={!!checked}
      />
    }
    {...rest}
  />
);

export const CheckboxInputWithLabelWrapper = (props) => {
  const { control, name, defaultValue, ...rest } = props;
  const { field } = useController({
    control,
    name,
    defaultValue,
  });

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          name={name}
          onChange={field.onChange}
          checked={!!field.value}
        />
      }
      {...rest}
    />
  );
};

export const TextFieldLimitedCharactersWrapper = (props) => {
  const { input, meta, limitedNum, form, title, ...rest } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const classes = useStyle();
  const [currentNumberCharacters, setCurrentNumberCharacters] = useState(0);

  const handleChange = (event) => {
    if (event.target.value.length > 40) {
      return;
    }
    form.change(title, event.target.value);
    setCurrentNumberCharacters(event.target.value.length);
  };

  return (
    <TextField
      {...input}
      {...rest}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className={classes.numberCharacters}>
            {currentNumberCharacters}/{limitedNum}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const TextInputLimitedCharactersWrapper = (props) => {
  const { control, name, defaultValue, disabled, limitedNum, ...rest } = props;
  const { field, fieldState, formState } = useController({
    control,
    name,
    defaultValue,
  });

  const showError = fieldState.error;
  const fieldError = path(['error', 'message'], fieldState);

  const classes = useStyle();
  const [currentNumberCharacters, setCurrentNumberCharacters] = useState(0);

  const handleChange = (event) => {
    if (event.target.value.length > 40) {
      return;
    }
    field.onChange(event.target.value);
    setCurrentNumberCharacters(event.target.value.length);
  };

  return (
    <TextField
      {...field}
      {...rest}
      disabled={disabled || formState.isSubmitting}
      helperText={fieldError}
      error={showError}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className={classes.numberCharacters}>
            {currentNumberCharacters}/{limitedNum}
          </InputAdornment>
        ),
      }}
    />
  );
};

TextInputWrapper.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};

TextInputWrapper.defaultProps = {
  control: null,
  name: '',
  defaultValue: undefined,
  disabled: false,
};

ReCaptchaInputWrapper.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
};

ReCaptchaInputWrapper.defaultProps = {
  control: null,
  name: '',
  defaultValue: undefined,
};

NumberInputFormatWrapper.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};

NumberInputFormatWrapper.defaultProps = {
  control: null,
  name: '',
  defaultValue: undefined,
  disabled: false,
};

CheckboxInputWithLabelWrapper.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};

CheckboxInputWithLabelWrapper.defaultProps = {
  control: null,
  name: '',
  defaultValue: undefined,
  disabled: false,
};

TextInputLimitedCharactersWrapper.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  limitedNum: PropTypes.number.isRequired,
};

TextInputLimitedCharactersWrapper.defaultProps = {
  control: null,
  name: '',
  defaultValue: undefined,
  disabled: false,
};

NumberFormatWrapper.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.any.isRequired,
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextFieldWrapper.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.any.isRequired,
};

TextFieldLimitedCharactersWrapper.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  limitedNum: PropTypes.number.isRequired,
  form: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

ReCaptchaWrapper.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.any.isRequired,
};

RadioWrapper.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.any.isRequired,
};

CheckboxWrapper.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.any.isRequired,
};

CheckboxWithLabelWrapper.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.any.isRequired,
};
