import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Router from 'next/router';
import { withStyles } from '@material-ui/core/styles';
import SimpleSearchForm from 'common/components/Forms/SimpleSearchForm';
import { getSearchList, getCategoryList } from 'common/store/actions';
import { propOr } from 'ramda';

const styles = (theme) => ({
  desktopFilters: {
    display: 'none',
    maxWidth: '540px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  mobileFilters: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  filters: {
    padding: '0px 10px 10px 0px',
  },
  listItemText: {
    fontWeight: 700,
    fontSize: '16px !important',
    lineHeight: '18px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      lineHeight: '16px !important',
    },
  },
});

class SearchBarHomePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFocused: false,
      isSearching: false,
    };
  }

  defaultValues = {
    searchText: '',
  };

  gainInputFocus = () => {
    this.setState({
      inputFocused: true,
    });
  };

  loseInputFocus = () => {
    // A slight timeout is required so that click actions can occur
    setTimeout(() => {
      this.setState({
        inputFocused: false,
      });
    }, 100);
  };

  handleSearchSubmit = (values) => {
    const searchText = propOr('', 'searchText', values);

    if (searchText === '') {
      Router.push(`/search`);
    }
    
    this.setState({
      isSearching: true,
    });

    // this.props.getSearchResults();
    Router.push(`/search?q=${values.searchText || ''}`);
  };

  handleCheckItem = (e) => {
    let { value } = e.target;
    console.log('Clicked value: ', value);
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, subcategories } = this.props;

    return (
      <Fragment>
        <div className={classes.filters}>
          <SimpleSearchForm
            initialValues={this.defaultValues}
            onSubmit={this.handleSearchSubmit}
            onFocus={this.gainInputFocus}
            onBlur={this.loseInputFocus}
            subcategories={subcategories}
            isLoading={this.state.isSearching}
          />
        </div>
      </Fragment>
    );
  }
}

SearchBarHomePanel.defaultProps = {
  searchLoading: false,
  disabled: false,
};

SearchBarHomePanel.propTypes = {
  searchLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  categories: ({ entities: { categories } }) => categories,
  search: ({ entities: { search } }) => search,
  searchLoading: ({ app: { searchLoading } }) => searchLoading,
  categoryLoading: ({ app: { categoryLoading } }) => categoryLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchList: (query) => dispatch(getSearchList(query)),
    getCategoryList: () => dispatch(getCategoryList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SearchBarHomePanel));
