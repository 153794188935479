import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Container, CircularProgress } from '@material-ui/core';
import EmailSignup from 'common/components/Forms/EmailSignupForm';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
});

class EmailSignupPanel extends React.Component {
  defaultValues = {
    email: '',
    type: 'subscriber',
  };

  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="sm" className={classes.container}>
        <EmailSignup initialValues={this.defaultValues} onSubmit={this.onSubmit} />

        {this.props.emailSubscriberLoading && (
          <Box mt={3} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </Container>
    );
  }

  preSubmit = ({ email, type }) => {
    return {
      email: email.trim(),
      type,
    };
  };

  onSubmit = async (values) => {
    const body = this.preSubmit(values);
    return this.props
      .emailSubscriber(body)
      .then(this.emailSuccessHandle, this.emailSuccessHandle);
  };

  emailSuccessHandle = () => {
    this.props.enqueueSnackbar({
      message: 'Thanks! You\'ll be hearing from us soon',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
      },
    });
  };
}

EmailSignupPanel.defaultProps = {
  enqueueSnackbar: () => null,
  emailSubscriberLoading: false,
};

EmailSignupPanel.propTypes = {
  emailSubscriber: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  emailSubscriberLoading: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
};

export default withStyles(styles)(EmailSignupPanel);
