import { connect } from 'react-redux';
import EmailSignupPanel from './EmailSignupPanel';
import {
  emailSubscriber,
  enqueueSnackbar
} from 'common/store/actions';

const mapStateToProps = state => ({
  emailSubscriberLoading: state.app.emailSubscriberLoading
});

const mapDispatchToProps = dispatch => ({
  emailSubscriber: data => dispatch(emailSubscriber(data)),
  enqueueSnackbar: args => dispatch(enqueueSnackbar(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSignupPanel);