/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';
import { colors } from 'common/constants';

export const FormContainer = styled.form`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const PaymentFormContainer = styled.form`
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`;

const buttonHeight = 37;

const borderInput = css`
  border-radius: 4px;
  border: 1px solid #cdd1d4;
  height: ${buttonHeight}px;
  background-color: #fff;
  color: #5c6872;
  vertical-align: middle;

  &:hover {
    background-color: #f5f6f7;
  }

  .fa {
    margin-left: 5px;
  }
`;

export const SubmitButton = styled.button`
  ${borderInput}
`;

const InputGroup = styled.div`
  input {
    ${borderInput}
    color: #3b4144;

    &:hover {
      background-color: #fff;
    }
  }
`;

const ButtonGroup = styled.div`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;

  & > button {
    ${borderInput}
  }
`;

const ButtonGroupPopup = styled.div`
  background-color: #fff;
  border: 1px solid #cdd1d4;
  color: #3b4144;
  padding: 10px;
  position: absolute;
  width: 320px;
  height: 180px;
  top: ${buttonHeight + 5}px;
  z-index: 101;
  overflow: auto;

  @media (min-width: 768px) {
    margin-left: -100px;
  }
`;

const OptionsGroup = styled.div`
  label {
    display: inline-block;
    margin-top: 5px;
    margin-right: 25px;
    overflow: hidden;

    & > input {
      float: left;
      margin-top: 4px;
      margin-right: 5px;
    }
  }
`;

const SelectLabel = styled.label`
  padding: 10px 5px;
  select {
    ${borderInput}

    min-width: 200px;
  }
`;

export const RadioContainer = styled.div`
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;

  &.active {
    background-color: #dae8f5;
  }

  .custom-control {
    padding: 10px;
  }

  .custom-control-label {
    display: block;
    cursor: pointer;

    &:after,
    &:before {
      display: none;
    }
  }
`;

export const PaymentCardContainer = styled.div`
  margin: 15px 0 5px;
  border: 1px solid ${colors.border};
  padding: 19px;
`;

export const PlanCard = styled.div`
  text-align: center;
`;

export const InvalidFeedback = styled.div`
  color: #a21010;
`;

export const ReCaptchaError = styled.label`
  font-size: 12px;
  line-height: 12px;
  color: #f44336;
`;

export const SearchSuggestionInput = styled.input`
  display: inline-block;
  border: none;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 0.9rem;
  outline: 0;
  border-radius: 5px;
  padding: 6px 10px 6px 30px;
  font-weight: 300;
  background-color: #333333;
  height: 30px;
  width: 100%;
`;
